.cart-page {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  flex-direction: column;
    padding-bottom: 0;
  display: flex;
  position: sticky;
  top: 0;
  height: calc(100vh - 20px);

  @media (max-width: 1200px) {
    height: calc(100vh - 55px);
  }
  @media (max-width: 1024px) {
    height: calc(100vh - 62px);
  }


  .header-wrap {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-top: 3.5rem;
    padding-bottom: 2.5rem;


    //----------------
  }

  .cart-items, .section-cart-billing, .step3 {
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    overflow-x: hidden;

    @media (max-width: 767px) {

    }
  }

  .header, .cart-row {
    .c1, .c2, .c3, .c4, .c5, .c6 {
      display: flex;
      align-items: center;
    }
    .c1 {
      width: 31%;
      padding-right: 1.5rem;

      .avatar-wrap.field {
        min-width: 52px !important;
        height: 52px;
        width: 52px;
      }
    }
    .c2, .c3, .c4, .c5 {
      width: 15%;
    }
    // .c2 {
    //   justify-content: center;
    // }
    .c6 {
      flex-grow: 1;
    }
  }

  .cart-row {
    background-color: #f7f7f7;
    border-radius: 5px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 2rem;

    h6 {
      font-size: 2rem;
      color: var(--gray);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .c1 {
      padding-left: 3rem;
      display: flex;

      h6 {
        color: var(--dark);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }


      figure {
        width: 52px;
        height: 52px;
        margin-right: 1.4rem;
      }

      .right {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;

        p {
          font-size: 1.4rem;
          color: var(--gray);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .c2 {
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 8.5rem;

        @media (max-width: 992px){
          svg {
            margin-right: 7px;
          }
        }

        h6 {
          font-size: 1.7rem;
        }
      }
    }

    .c5 {
      h6 {
        color: var(--dark);
      }
    }
  }
}

.cart-page {
  .total-wrap {
    margin-top: 5rem;
    display: flex;
    justify-content: flex-end;

    .total {
      display: flex;
      flex-direction: column;
      text-align: right;


    }
  }
  .price {
    font-size: 2.4rem;
  }
}


.route-section.Cart {
  max-height: 100%;
  overflow: hidden;

  .container-sm {
    overflow: hidden;
  }
}


@media (max-width: 767px){
  .route-section.Cart {
    .cart-items {
      .row.header {
        display: none;
      }

      .row.cart-row {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .c2, .c3, .c4 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 33%;
          padding: 0 1.5rem;
        }

        .c1, .c6 {
          margin-bottom: 1.5rem;
        }
        .c1 {
          order: -1;
          width: 80%;
          padding-left: 1.5rem;
        }
        .c6 {
          order: 1;
          width: 20%;
        }
        .c2, .c3, .c4, .c5 {
          order: 2;
        }
      }

      .bottom  {
        margin-right: -15px;
        margin-left: -15px;
      }
    }

    .c2 {
      .main {
        display: inline-block;
        flex-direction: row;
        width: 100%;

        svg {
          width: 1.5rem;
        }
        h6 {
          display: inline;
        }
      }
    }

    .c5 {
      width: 100%;
      padding: 0 15px;
      margin-top: .5rem;
      font-size: 1.8rem;
      .legend {
        font-size: 1.8rem;
        margin-right: 15px;
      }
    }

    .legend {
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      font-size: 1.2rem;
      white-space: nowrap;
    }
    h6 {
      font-size: 1.6rem;
    }

    .cart-row .c1 .avatar-wrap.field  {
      width: 40px !important;
      height: 40px !important;
      min-width: 40px !important;
    }

    .total-wrap.row {
      margin-top: 2.5rem;
    }

  }
}

@media (min-width: 768px){
  .cart-items {
    .legend {
      display: none;
    }
  }
}