.pro-sidebar {
  header {
    height: 88px !important;
    margin-top: 5px;

    h2 {
      font-size: 2.8rem;
      color: black;
      font-weight: bold;
      transform: skew(-10deg, 0deg) scale(1.2, 1.1);
    }

    svg {
      margin-right: .5rem;
      min-width: 4rem;
      height: 3.6rem;
      margin-left: .8rem;
    }
  }

  > .pro-sidebar-inner {
    background-color: var(--light);
  }

  .pro-inner-item {
    margin: 8px 2rem 2rem 25px;
    padding: .3rem 0 .3rem 1.3rem !important;
    border-radius: 4px;
  }

  .pro-icon-wrapper {
    background-color: var(--light) !important;
  }

  &.collapsed {
    .pro-sidebar-header {
      h2 {
        opacity: 0;
        transform: translate3d(-5px, 0, 0);
      }
    }
    .pro-sidebar-footer {
      button {
        transform: rotate(180deg);
      }
    }
  }

  .pro-sidebar-footer {
    @media(min-width: 1200px){
      display: block !important;
    }

    padding-left: 2.5rem;
    border-top: none !important;

    ul.footer-links {
      display: flex;
      margin-bottom: .5rem !important;
      flex-wrap: wrap;

      li {
        margin-right: .8rem;
        white-space: nowrap;
      }
    }

    a {
      color: #adadad;
      font-size: 1.2rem;
    }
    p {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

.pro-sidebar-header {
  font-weight: bold;
  letter-spacing: 1.42px;

  border-bottom: none !important;
  h2 {
    margin-bottom: 0;
    transition: all 0.2s ease-in-out;
    transform-origin: left;
    letter-spacing: 0;
    font-size: 2.2rem;
  }
  svg {
    margin-right: 5px;
    min-width: 35px;
  }
}

.pro-sidebar-content {
  .pro-icon {
    background-color: var(--light) !important;
  }
  svg {
    width: 2.2rem;
    height: 2.2rem;
    fill: var(--gray);
  }
}

.pro-sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  padding-top: 3rem;
  padding-bottom: 3rem;

  button {
    transition: transform 0.3s ease;
    background: transparent;
    border: none;
    padding: 0.2 1rem;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }

  .pro-icon-wrapper {
    background: white;
  }

  // .pro-sidebar-content {
  .pro-item-content {
    font-size: 17px;
    color: #9d9d9d !important;
  }
}

.pro-menu-item {
  &.active {
    .pro-inner-item {
      background-color: white;

      .pro-icon-wrapper {
        background-color: white !important;
      }
    }
    .pro-icon {
      background-color: white !important;
      svg,
      g,
      path {
        fill: black;
      }
    }
    .pro-item-content {
      color: black;
      font-weight: bold;
    }
  }

  .pro-item-content {
    font-size: 1.8rem;
  }
  .counter {
    background-color: var(--warning);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    padding: 0px;
    margin-left: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    right: 15px;
  }
}


@media (max-width: 1200px){
  .pro-menu-item {
    .counter {
      right: 0;
      bottom: 0;
      left: 6px;
      top: -6px;
    }
  }
}
@media (max-width: 992px){
  .pro-menu-item {
    .counter {
      right: -6px;
      bottom: 27px;
    }
  }

  .menu-item-notification {
    .pro-menu-item {
      .counter {
        right: 2px;
        bottom: 33px;
        left: 20px;
        top: -7px;
      }
    }
  }
}

$sidebar-bg-color: var(--light);
$icon-bg-color: var(--light);
$sidebar-color: var(--gray);
$icon-size: 50px;
// $sidebar-collapsed-width: 95px;

@import "~react-pro-sidebar/dist/scss/styles.scss";


$breakpoints-md: '1200px';
$breakpoints-sm: '992px';

@media (max-width: $breakpoints-md) {
  body .c-wrapper {
    > nav {
      position: fixed;
      bottom: 0;
      width: 100vw;
      border-top: 2px solid var(--light);
      background: white;
      z-index: 3;

      .pro-sidebar {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;

        > .pro-sidebar-inner {
          background-color: white;
        }

        .pro-icon-wrapper, .pro-icon  {
          background-color: white !important;
        }

        nav.pro-menu {
          .pro-menu-item > .pro-inner-item  {
            margin: 0;
            padding: 0 !important;
          }
        }
      }

      .pro-sidebar-content {
        nav.pro-menu {
          padding: 0;
          height: 100%;
          overflow-x: auto;

          ul {
            height: 100%;
            display: flex;
            justify-content: space-between;

            > li, > div {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 8px 15px;
              width: 25%;
              justify-content: center;
            }
          }
        }
      }

      .pro-sidebar-header, .pro-sidebar-footer {
        display: none;
      }
    }
  }
}

@media (min-width: $breakpoints-sm) and (max-width: $breakpoints-md) {
  body .c-wrapper {
    > .content {
      max-height: calc(100% - 51px);
      overflow-y: auto;
      position: fixed;
    }
  }
}



@media (max-width: $breakpoints-sm) {
  body .c-wrapper {
    > .content {
      max-height: calc(100% - 62px);
      overflow-y: auto;
      position: fixed;
    }

    > nav {
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
        height: 28px;
      }
      nav.pro-menu {
        > ul {
          > li, > div {
            min-width: 25%;
          }
          .pro-icon-wrapper {
            margin-right: 0 !important;
          }
          .pro-inner-item {
            flex-direction: column;
            justify-content: center;
            .pro-item-content {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
