.btn-select-report {
  border-radius: 12px !important;
  padding: .8rem 1.5rem !important;
  text-align: left !important;
  height: unset !important;
  width: 18rem;
  font-size: 1.6rem;


  &.has-selected-report {
    border-color: var(--primary) !important;
  }

  h4 {
    margin-bottom: .4rem;
    font-size: 1.3rem;
    letter-spacing: 0.02em;
    color: var(--gray);
    font-weight: normal;
    position: relative;
  }

  &.reports-empty {
    font-size: 1.4rem;
  }
}

.reports-sorting {
  margin-bottom: 1.5rem;
  p {
    margin-bottom: 1rem;
  }
  .actions-wrap {
    background-color: var(--light);
    border: 4px solid var(--light);
    border-radius: 12px;
    padding: .2rem;

    button {
      width: 50%;
      border: none;
      border-radius: 12px;
      padding: 1rem .5rem;
      font-weight: bold;
      background-color: var(--light);

      &:focus, &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &.active {
        background-color: white;
        border-radius: 8px;
      }
    }
  }
}

.modal-select-report .form-group {
  display: flex;
  flex-wrap: wrap;

}

.form-group.report-option.form-radio {
  display: block;
  border-radius: 8px;
  border: solid 2px var(--light);
  width: 48%;
  margin-bottom: 1.5rem !important;


  &:nth-of-type(odd){
    margin-right: 2%;
  }

  .form-radio-label {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    color: black;
    font-weight: bold;
    padding: 1.2rem .5rem 1.2rem 1rem;
    background-color: var(--light);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #e4e4e4;

    input {
      margin-right: 1.5rem;
    }

    h5 {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  main {
    padding: 1.2rem 1rem 1.4rem 1rem;
  }

  .status {
    font-size: 1.4rem;
  }

  &.not-selectable {
    cursor: not-allowed;
    opacity: 0.4;
    label {
      cursor: not-allowed;
    }
  }

  &.active {
    border-color: var(--primary);
    box-shadow: inset 0 0 60px 5px #ffffff0a, 3px 3px 5px 0 #ccc;
  }

  .icon {
    margin-right: .8rem;

    &.second {
      margin-left: 1.3rem;
      width: 1.4rem;
      height: 1.4rem;
      background-color: var(--warning);
      border-radius: 50%;
      display: inline-block;
    }
  }

  .report-option-completed {
    display: flex;
    align-items: center;
  }

  &.processing .status span {
    animation: changeColor 1s infinite !important;
  }
}

@keyframes changeColor {
  from {
    color:  var(--light);
  }
  to {
    color: var(--primary);
  }
}


.modal-edit-field  {
  .modal-body {
    padding: 0;

    main {
      margin-top: -3rem;
    }
  }
  form {
    button {
      margin-top: 2rem;
    }

    .form-group {
      width: 99%;
      margin-left: auto;
      margin-right: auto;

    }
  }

  form.delete-field {
    margin-top: 4rem;
  }
}
