.grid-2 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 33%;

  .list-item {
    cursor: pointer;
  }


  .main > div.list-item {
    display: flex;
    padding: 2rem 3rem;

    h4 {
      font-size: 2rem;
    }

    &:first-of-type {
      svg {
        background-color: #c7d4d3;
      }
    }

    svg {
      padding: 16px;
    }

    &:hover {
      background-color: #f7f7f7;
    }
  }
}

@media (min-width: 1480px){
  .grid-2 {
    width: 31%;
  } 
}



.grid-2 {
  box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.04);

  header {
    position: sticky;
    top: 0;
    padding: 33px 20px 10px 20px;
    background-color: white;
    max-width: 100%;

    h2 {
      font-weight: bold;
      font-size: 2.8rem;
      overflow-x: hidden;
      text-overflow: ellipsis;

      @media (max-width: 767px){
        font-size:2.8rem;
      }
    }
  }

  .main {
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;
  }

  .bottom {
    display: flex;
    padding: 2rem 3rem;
    position: sticky;
    bottom: 0;
    border-top: 1px solid rgba(173, 173, 173, 0.2);

    button {
      width: 100%;
      height: 5rem;
      font-weight: bold;
    }
  }

  .grid-empty {
    padding: 2rem 3rem;
  }
}


.grid-2 {
  .list-item {
    &.active {
      background-color: var(--light);
    }


    &:nth-of-type(n){
      svg {
        background-color: #c7d4d3;
      }
    }
    &:nth-of-type(2n){
      svg {
        background-color: #cbc7d4;
      }
    }
    &:nth-of-type(3n){
      svg {
        background-color: #cdd4c7;
      }
    }
    &:nth-of-type(4n){
      svg {
        background-color: #d4c9c7;
      }
    }
  }
}

@media (max-width: 767px) {
  .grid-2 {
    width: 100vw;
    min-width: 100vw;
    transition: transform .3s ease-in;

    &.has-active {
      transform: translate3d(-100%, 0, 0);
    }

    header {
      padding: 1.5rem 2rem;
      z-index: 1;
    }

    .bottom {
      padding: 1.25rem 2rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-2 { 
    .avatar-wrap {
      display: none;
    }
  }
}