// .modal-content {
  .tab-security-form {
    display: block;
    width: 465px;
    max-width: 100%;

    .form-group {
      text-align: left;
    }

    .btn-wrap {
      margin-top: 2rem;
    }

    .invalid-feedback.active {
      position: relative;
      display: block;
    }
  }
// }
.grid-3.loading {
  background: rgba(255, 255, 255, 0.5);
}

.route-profile-section.Security {
  .inner {
    width: 460px;
    max-width: 100%;

    header {
      margin-bottom: 0 !important;
    }
  }

  .settings-section {
    padding: 5rem 0;
  }
} 