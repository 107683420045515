.satellite-graphs {
  // height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 2.6rem;
  // background-color: #fafafa;
  // border-radius: 5px;
  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

  > div {
    height: 360px !important;
  }
}

.recharts-text.recharts-cartesian-axis-tick-value {
  font-weight: bold;
  font-size: 1.4rem;
}