

.content > main .route-section {
  display: flex;
}



/* grid 3 */
/*-------*/
.route-section {
  &.Fields, &.Shoots {
    .grid-3 {
      padding-top: 0;
      overflow: visible;
    }
  }
}



@media (min-width: 768px){
  .grid-3 {
    max-height: 100vh;
    overflow: visible; // style change: visible
    width: 67%;
    padding-top: 4rem;
    min-height: 100vh;
    animation: fade .6s forwards;
    // max-width: calc(100vw - 270px - 31vw);
  }
}

@media (min-width: 1024px){
  .grid-3 {
    // overflow: auto; // style change: visible
  }
}

@media (min-width: 1480px){
  .grid-3 {
    width: 69%;
  }
}


@media (max-width: 767px) {
  .grid-3 {
    width: 100vw;
    min-width: 100vw;
    transition: transform .3s ease-in;
    transform: translate3d(0, 0, 0);
    box-shadow: none !important;

    &.is-active {
      transform: translate3d(-100%, 0, 0);
    }


  }
}




.grid-3 {
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04);

  &.grid-w-100 {
    .inner {
      max-width: 100%;
      text-align: left;
      width: 100%;
      max-height: 100vh;
      overflow: scroll;
      overflow-x: hidden;

      main {
        padding: 15px 50px;
      }
    }
  }

  .inner {
    width: 510px;
    max-width: 100%;
    margin: auto;
    text-align: center;

    header {
      figure {
        margin: 0 auto 2rem auto;
        width: 175px;
        height: 175px;
        border-radius: 50%;
        overflow: hidden;
        max-width: unset;

        svg {
          background-color: #c7d4d3;
          padding: 45px;
          width: 100%;
          height: 100%;
        }
      }

      h2 {
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: -0.01em;
        margin: 0;

        @media(max-width: 992px){
          font-size: 2.8rem;
        }
      }
      p {
        margin: 0;
      }
    }
  }

}

@media (max-width: 1023px) {
  .grid-3 {
    &.grid-w-100 {
      .inner {
        main {
          padding: 15px 15px;
        }
      }
    }
  }
}