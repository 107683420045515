@media (min-width: 768px){
  .icon-back-sm {
    display: none !important;
  }
}
.icon-back-sm {
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  padding: 7px !important;

  &.block {
    margin: 20px 15px;
  }

  &.fixed {
    margin-left: 15px;
    z-index: 1000;
    top: 20px;
    left: 15px;
    // background-color: white;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;

    background-color: #ffffff0f;
    backdrop-filter: blur(4px);

    svg {
      stroke: #c9c9c9;;
    }
  }
}

.icon-back-sticky {
  position: sticky;
  background: transparent;
  z-index: 100;
  height: 0;
  top: 20px;
  left: 15px;
}