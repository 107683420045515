.route-profile-section.UserOrders {
  .c-list-group  {
    .order-row-wrap {
      button {
        width: 100%;
        display: flex;
        padding: 1rem 0;
        background: transparent;
        color: black;
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0;

        &:focus {
          box-shadow: none;
        }

        .order-row {
          display: flex;
          flex-grow: 1;
          .inner {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            max-width: 100%;
            line-height: 33px;
            p {
              margin-bottom: 0;
            }

            .date {
              font-weight: bold;
            }

            .price {
              // margin: 0 42px;
            }

            .status {
              position: relative;
              padding-left: 20px;
              white-space: nowrap;
              &.complete {
                color: var(--success);

                &::before {
                  background-color: var(--success);
                }
              }

              &.pending_payment {
                color: var(--warning);
                &::before {
                  background-color: var(--warning);
                }
              }

              &::before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: black;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
              }
            }
          }
        }

      }
    }
  }
}

.route-profile-section.UserOrders {
  .cart-page {
    padding-top: 0;

    p {
      margin-bottom: 0;
    }

    .cart-row {
      display: flex;
    }

    .cart-row.header {
      padding: 10px 0;
      margin-bottom: 0;
      font-weight: bold;
      background-color: white;

      p {
        margin-bottom: 0;
      }
      .c1 {
        padding-left: 0;
      }
    }
  }
}

@media (min-width: 768px){
  .route-profile-section.UserOrders {
    .c-list-group  {
      .order-row-wrap {
        .order-row {
          .inner {
            .date {
              width: 20%;
            }

            .price {
              // width: 50%;
              // margin: 0 70px;
              width: 34%;
            }

            .status {
              width: 30%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  .route-profile-section.UserOrders {
    .grid-3 {
      > .inner header {
        margin-bottom: 1rem;
      }
    }
    .order-row {
      .inner {
        flex-wrap: wrap;
      }
      .price {
        flex-grow: 1;
        margin: 0 !important;
      }

      .status {
        width: 100%;
        text-align: left;
      }
    }
    .cart-page {
      padding: 0 !important;

      .cart-row {
        padding: 1.5rem 0;
        flex-wrap: wrap;

        .c2 .main {
          width: unset;
        }
      }
    }
  }
}