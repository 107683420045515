.chart-subtitle {
  display: flex;

  p {
    margin-right: 1rem;
  }
}

.recharts-legend-wrapper {
  bottom: 0 !important;
}