#deploy-logs-section {
  padding: 0;
  max-height: 455px;
  height: 455px;
  overflow-y: auto;

  li {
    margin-bottom: 1rem;

    &:nth-of-type(odd) {
      background-color: white;
    }
  }
}