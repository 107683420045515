section.agro-soil-data {
  display: flex;
  align-items: center;
  transform: translateZ(1px);
  display: flex;
  align-items: center;
  border-top-right-radius: 17px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      padding: 0 0.75rem;
      font-size: 1.3rem;
      border-left: 2px solid var(--light);

      @media (max-width: 1200px) {
        font-size: 1.3rem;
      }
      @media (max-width: 640px) {
        font-size: 1.2rem;
      }
    }
  }
}