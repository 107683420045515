$section-width: 95%;

section.fields {

  > header {
    max-width: $section-width;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 2px solid #e8ecee;
    padding-bottom: 1rem;
    margin-bottom: 2.5rem;
  }
}

section.fields {

  .list-header {
    margin-top: 5rem !important;
  }

  .list-items-container {
    max-width: $section-width;
    margin-left: auto;
    margin-right: auto;
  }
}