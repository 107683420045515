.modal-dialog.modal-calendar {
  max-width: 90%;

  .modal-title {

    .colors-info {
      display: inline;
      margin-left: 2rem;
    }
    span {
      font-size: 1.5rem;

      &.blue, &.yellow, &.green {
        width: 15px;
        height: 15px;
        background-color: gray;
        display: inline-block;
        border-radius: 50%;
      }

      &:nth-of-type(even){
        margin-right: 1.8rem;
        margin-left: .4rem;
      }

      &.blue {
        background-color: #3174ad;
      }
      &.yellow {
        background-color: var(--warning);
      }
      &.green {
        background-color: var(--primary);
      }
    }
  }

  .modal-content {
    padding: 2rem !important;
  }
}

.modal-backdrop-calendar {
  background: black !important;
}

.modal-backdrop-calendar.event-info.show {
  opacity: 0;
}

.modal-calendar.event-info{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;

  h2 {
    border: 2px solid;

    &.category {
      &-alternative {
        border-color: var(--warning);
        color: var(--warning);
      }
      &-processed, &-final {
        border-color: var(--success);
        color: var(--success);
      }
      &-confirmed {
        border-color: #3174ad;
        color: #3174ad;
      }
    }
  }
}

.rbc-calendar {
  .category-final {
    background-color: var(--primary);
  }

  .category-alternative {
    background-color: var(--warning);
  }
}


.rbc-agenda-table {
  .category-final {
    color: white !important
  }

  .category-confirmed {
    background-color: #3174ad;
    color: white;
  }
}