.modal-dialog.modal-notifications .modal-content {

  .modal-body {
    padding: 0 !important;
  }

  .empty {
    padding-left: 2.5rem;
  }
  .notification-item {
    padding: 1rem 2.4rem;
    border-bottom: 2px solid var(--light);

    &:first-of-type {
      padding-top: 0;
    }

    &.unread {
      background-color: var(--light);

      .new-status {
        color: var(--success);
      }
    }

    header {
      display: flex;
      align-items: center;

      h4 {
        margin: 0 0 0 .5rem;
        font-size: 1.8rem;
      }
    }

    .date-time {
      color: var(--gray);
      margin: .5rem 0;
      font-size: 1.4rem;

      span {
        margin: 0 5px;
      }
    }

    .details {
      display: flex;

      .icon {
        margin-right: 1rem;

        svg {
          width: 5rem;
          border: 1px solid;
          border-radius: 8px;
          padding: 3px;
        }
      }

      .avatar-wrap {
        width: 5rem;
        height: 5rem;
        min-width: 5rem;
        margin-right: 1rem;
      }

      .title-location {
        p {
          margin-bottom: 0;

          &.location {
            color: var(--gray);
          }
        }
      }
    }
  }
}