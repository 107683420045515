.modal-edit-field  {
  .modal-body {
    padding: 0;

    main {
      margin-top: -3rem;
    }
  }
  form {
    button {
      margin-top: 2rem;
    }

    .form-group {
      width: 99%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  form.delete-field {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .btn-group-toggle {
    display: flex;
    flex-direction: column;

    .form-label {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .form-check {
      margin-bottom: 1rem;
    }

    .form-check-label {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}