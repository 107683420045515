form {
  border-radius: 4px;

  .section-icon {
    margin-bottom: 1rem;
    text-align: center;

    > div {
      display: inline-block;
      padding: .5rem;
      border-radius: 50%;
      background-color: black;
    }
  }

  .invalid-feedback {
    position: absolute;
    max-width: 100%;
  }
}

.form-group {
  margin-bottom: 0;
  position: relative;
}

.form-control {
  border: none !important;
  font-weight: bold;
  background-color: var(--light);
  height: 5.8rem;
  padding: 2rem 0 0 1.5rem;
  font-size: 1.8rem;

  &.input-search {
    padding: 30px 2rem 30px 65px;
  }
}

.search-group {
  position: relative;
  margin: 2rem 0 1rem 0;

  svg {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: unset !important;
    height: unset !important;
  }
}


.form-group.focused label, 
.form-group.label-shrink label {
  transform: translate(0, 4px) scale(0.75);
}

.form-group label {
  position: relative;
  top: 2.8rem;
  left: 1.5rem;
  font-size: 1.2rem;
  color: var(--gray);
}

.form-group.form-radio {
  margin-bottom: 1rem !important;
  label {
    cursor: pointer;
    font-size: 1.6rem;
  }

  .form-radio-label {
    display: flex;
    top: 0;
    left: 0;

    input {
      width: 1.6rem;
      height: 1.6rem;
      top: 3px;
      position: relative;
    }

    > span {
      padding-left: 1rem;
      color: black;
      font-weight: bold;
    }

    .two-line {
      display: flex;
      flex-direction: column;

      .line-1 {
        padding-left: 1.5rem;
        font-size: 2rem;
        color: black;
        font-weight: bold;
      }
      .line-2 {
        padding-left: 1.5rem;
        font-size: 1.4rem;
        color: var(--gray);
      }
    }
  }
}

.form-check-group {
  .form-check {
    display: flex;
    align-items: center;

    input {
      position: relative;
      top: -3px
    }

    label {
      padding-left: 1rem;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px){
  .form-group {
    margin-bottom: -1rem !important;
  }
}