.section-cart-billing {
  h5.section-title {
    margin-bottom: 3rem;
    font-weight: lighter;
  }


  a.btn-edit-invoice,
  button.btn-new-address {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 2rem;
  }

  a.btn-edit-invoice {
    display: inline-flex;
    align-items: center;

  }

  button.btn-new-address {
    margin-right: 2rem;
    width: unset !important;
  }

  .billing-address-actions {
    display: flex;
    flex-wrap: wrap;
  }

  .billing-addresses {
    display: flex;
    flex-wrap: wrap;

    .row {
      width: 100%;
    }

    .billing-address {
      margin-bottom: 2rem;
      max-width: 100%;

      h4 {
        font-size: 2rem;
        margin-bottom: 5px;
      }
    }

    + .btn-primary {
      background: white;
      color: black;
      padding: 0;
      border: none;
    }
  }

  .section-addresses {
    .form-check {
      width: 100%;
      padding: 2rem;
      border: 2px solid var(--light);
      border-radius: 8px;
      cursor: pointer;
      transition: border .2s ease;

      &.active {
        border-color: var(--primary);
      }

      &:hover {
        background-color: var(--light);
      }

      h5, p {
        color: #adadad;
        font-size: 1.6rem;
      }

      input {
        margin-right: 1.5rem;
      }

      label {
        padding-left: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .section-transaction {

    .form-check {
      label {
        font-size: 1.8rem;
      }
    }

    .invoice-wrap {
      max-width: 80rem;
      padding: 2rem 1.5rem 3rem 1.5rem;
      padding-bottom: 0;
      margin-left: auto;
      margin-right: auto;

      .completed {
        .row {
          &:nth-of-type(odd){
            background-color: var(--light);
          }

          .col-md-6 {
            padding-top: 10px;
            padding-bottom: 10px;

            &.left {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .add-telephone-section {
    text-align: center;


    > div {
      display: inline-block;
      background-color: var(--light);
      padding: 2rem 1.5rem;
      margin: 2rem 0;
      width: 42rem;
      max-width: 100%;
    }

    p {
      margin: 1rem 0;
    }
  }
}

.section-cart-billing, .cart-items {
  .body {
    flex-grow: 1;
  }

  .bottom-wrap {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;

    @media (max-width: 767px){
      background: white;
    }
  }
}


@media (max-width: 767px){
  .section-cart-billing .section-transaction .invoice-wrap {
    padding: 2rem 0 0 0;
  }
}