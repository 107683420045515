.avatar-wrap {

  overflow: hidden;
  // box-shadow: 1px 3px 8px #c7d4d3;

  img {
    border-radius: 50%;
  }

  &.field, &.order, &.user, &.company {
    box-shadow: none;
    max-width: 25%;
    width: 8rem;
    min-width: 7rem;
    margin-right: 2rem;

    img {
      border-radius: 8px;
      // width: 9rem;
    }
  }
}
