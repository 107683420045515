section.guest-page {
  .right {
    .inner {
      form {
        width: 40rem;
        max-width: 100%;
      }
    }
  }

  h1 {
    font-weight: bold;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .loader-wrap {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, .8);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verify-complete {
    width: 40rem;
    max-width: 100%;
  }
}

.sign-up-wrap {
  form {
    button[type="submit"]{
      margin-bottom: 1rem;
    }
  }
  .user-type-wrap {
    margin: 2rem 0;

    @media (max-width: 768px) {
      margin-bottom: 2.5rem !important;

      .form-group {
        margin-bottom: 1.5rem !important;
      }
    }
  }

  @media (max-width: 768px){
    .form-check-group {
      margin-bottom: 2rem !important;
    }

    .login-wrap {
      margin-top: 2rem;
    }
  }
}

section.guest-page .right .inner.vertical-center {
  .form-groups {
    margin-bottom: 3rem;
  }
}


@media (min-width: 768px) and (min-height: 701px){
  section.guest-page .right .inner {
    padding-bottom: 10vh;
  }
}




@media (min-width: 768px){
  section.guest-page .right {
    box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.04);

    .inner {
      margin-top: 0;
      min-height: 100vh;
      display: flex;
      align-items: center;
      position: relative;
    }
  }
}


@media (min-width: 1400px){
  section.guest-page {
    .right {
      .inner {
        padding-left: 8vw;
      }
    }
  }
}
@media (max-width: 1400px){
  section.guest-page {
    .right {
      .inner {
        form {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media (max-width: 767px){
  section.guest-page {
    h1 {
      margin-bottom: 0;
    }

    .left {
      order: 2;
      margin-bottom: 3rem;
    }

    .right {
      margin-bottom: 3rem;

      .inner form {
        // width: 40rem;
      }
    }
    main {
      padding-top: 5.5rem;
      margin-top: 2rem;

      form {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

