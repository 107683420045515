.map-bottom-wrap {
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  bottom: 0;
  position: absolute;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  padding: 0 .5rem 0 0;
  box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.04);
  box-shadow: -2px 5px 15px 3px rgba(0, 0, 0, 0.04);

  @media (max-width: 1480px) {
    flex-direction: column;;
  }

  // @media(max-width: 767px){
  //   display: none;
  // }
}

.section-weather {
  display: flex;
  align-items: center;
  transform: translateZ(1px);

  @media (max-width: 767px){
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
    bottom: unset;
    animation: fadeIn 2s forwards;
  }



  .temp_c {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 .75rem;
    color: black;
    padding-right: 4px;
    border-right: 2px solid #e7e7e7;
    font-size: 1.3rem;

    &:first-of-type{
      border-right: none;
    }
    &:nth-of-type(2) {
      border-left: 2px solid #e7e7e7;
    }
    &:last-of-type {
      border-right: none;
    }


    svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: .5rem;
    }

    @media(max-width: 767px){
      font-size: 1.2rem;
      padding: 0 0.5rem;
    }

    // &.astro {
    //   @media (max-width: 640px) {
    //     display: none;
    //   }
    // }
  }

  img {
    max-width: 3.5rem;
    height: auto;
    animation: fadeIn .2s forwards;
  }
}





@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div[class^="range"]{
  font-weight: bold;
  font-size: 16px;
}