.tab-profile {
  .row:nth-last-child(n + 3) {
    margin-bottom: 0rem;
  }

  .row {
    text-align: left;

    &.action {
      padding: 0 15px;
    }
  }

  .user-info {
    margin-top: 3rem;
  }

  &.grid-3 {
    .inner {
      width: 620px;
      max-width: 100%;
    }
  }
}

.tab-profile {
  a {
    color: var(----gray-dark);

    &.active {
      color: var(--primary);
    }
  }

}


.section-upload-photo {
  legend {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .avatar {
    border-radius: 50%;
  }

  form {
    text-align: center;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    input {
      height: 25px;
      line-height: 30px;
    }

    .note {
      font-size: 1.5rem;
    }
  }

  form.avatar input[type="file"],
  form.avatar input[type="file"]:before {
    display: block;
    width: 184px;
    line-height: 22px;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
  }
  form.avatar input[type="file"] {
    position: relative;
    margin-bottom: 3px;
    margin-top: 3px;

    &:hover {
      &::before {
        text-decoration: underline;
      }
    }

    p {
      width: 100%;

      &.note {
        font-size: 1.3rem;
      }
    }
  }

  form.avatar {
    align-items: center;
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .btn {
        font-size: 1.51rem;
        top: -3px;
        position: relative;
      }
    }
  }

  form input[type="file"]:before {
    // content: "Upload your photo";
    content: attr(data-before);
    position: absolute;
    top: -1px;
    left: 0;
    background: #fff;
    height: 34px !important;
    width: 181px;
    line-height: 27px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }

  // form input[type="file"].new:before {
  //   content: "Upload new photo";
  // }

  form button[type="submit"] {
    display: block;
    border: none;
    color: #fff;
    cursor: pointer;
    background: #2a76cd;
    width: 140px;
  }
  .PlaceholderAvatar {
    margin-right: 30px;
  }

  .PlaceholderAvatar {
    background: transparent;
    border: 1px dashed #9ca6af;
    fill: #9ca6af;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    transition-duration: 200ms;
    transition-property: border;

    &--xlarge {
      height: 100px;
      width: 100px;
    }
  }
}