$font-size-base:              1.6rem !default; // Assumes the browser default, typically `16px`
$form-group-margin-bottom: 2rem;
$input-height: 3rem;
$border-width: .2rem;
$border-radius: 4px;
$label-margin-bottom: 0;
$form-group-margin-bottom: 4rem;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300&display=swap');

@import "~bootstrap/scss/bootstrap";
@import "./styles/Modal.style.scss";
@import "./styles/Typography.style.scss";
@import "./styles/Form.style.scss";
@import "./styles/Grid.style.scss";
@import "./styles/components/Avatar.style.scss";
@import "./styles/components/IconBack.style.scss";

@import "./styles/grid/Grid2.style.scss";

:root {
  --light: #f6f5f8;
  --gray: #9b9b9b;
  --primary: #1c4d00;
  --warning: #FFAF0B;
}


.rounded-2 { border-radius: 8px; }

a {
  color: var(--primary);

  &:hover {
    color: var(--black);
    text-decoration: none;
  }
}

ul, li {
  list-style: none;
}

h2 {
  font-weight: bold;
}

h1, h2, h3, h4,h5,h6 {
  margin-bottom: 0;
}

.h4, h4 {
  font-size: 2.0rem;
}

h3, .h3 {
  font-size: 2.4rem;
}

figure, p {
  margin-bottom: 0;
}
.link-small {
  padding: 6px 8px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover, &:active {
    background-color: rgba(49, 27, 146, 0.04);
  }
}

.border-width-2 {
  border-width: .2rem !important;
}


// a {
//   text-align: center;
// }


.bs-none {
  &:active,&:focus {
    box-shadow: none;
  }
}


.c-btn-lg {
  font-weight: bold;
  height: 5rem;
  padding: 0 2.9rem;
  font-size: 1.5rem;
}
a.c-btn-lg {
  line-height: 4.6rem;
}

.modal-dialog {
  .modal-title {
    font-weight: bold;
  }
}




.c-wrapper  {
  > nav {
    z-index: 1;
  }
  > .content {
    z-index: 2;
    width: 100%;
  }
}

.cursor-pointer{
  cursor: pointer;
}



/* MODAL STYELS */

.position-unset {
  position: unset !important;
}

.fade-in {
  animation: fade .6s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

button:disabled {
  cursor: not-allowed;
}


.form-group.telephone  {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.table-wrap {
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 1.5rem;

    @media (max-width: 767px){
      // font-size: 2.4rem;
    }
  }
  .row {
    padding: 1rem 0;

    div[class^="col-"] {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-of-type(odd){
      background-color: var(--light);
    }

    strong {
      span {
        margin-left: 5px;
        color: #b2a9a9;
        font-size: 1.4rem;

      }
    }
  }
}

.marker {
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23FFAF0B' stroke-width='0' viewBox='0 0 20 20' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  top: -18px;
}