@media (min-width: 768px){
  .route-profile-section.UserOrders {

    .cart-row {
      p.legend {
        display: none;
      }
    }
  }
}

@media (max-width: 768px){
  .route-profile-section.UserOrders {
    .cart-row {

      &.header {
        display: none;
      }

      .c1, .c2, .c3, .c4 {
        width: 100%;
        padding: 1rem 2rem;
      }

      .c2, .c3, .c4 {
        justify-content: space-between;

        svg {
          display: none;
        }
      }
    }
  }
}