.analytics-wrap {

  h5 {
    margin-bottom: 2rem;
  }

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1%;

    li {
      margin-right: 1%;
      margin-left: 1%;
      margin-bottom: 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--light);
      padding: 3rem 1.5rem;
      border-radius: .8rem;
      box-shadow: 2px 2px 2px gainsboro;
      width: 18%;

      @media (max-width: 1400px) {
        width: 23%;
      }
      @media (max-width: 1024px) {
        width: 31.33%;
      }
      @media (max-width: 767px) {
        width: 48%;
        padding: 1.5rem .75rem;
      }


      h4 {
        font-size: 1.6rem !important;
        font-weight: 400;
        margin-top: .4rem;
        margin-bottom: .4rem;
        color: var(--dark);
        text-align: center;
      }

      p {
        font-weight: bold;
        font-size: 2.1rem;
        letter-spacing: .01rem;
      }
    }
  }

  .analytics-reports {
    padding-top: 2rem;
  }
}