.satellite-calendar {
  h3 {
    margin-bottom: 1.5rem;
  }
}

.satellite-calendar-stats {
  display: flex;
  // padding: 0.5rem 0;
  margin-bottom: 2rem;
  transition: all .2s ease;

  .loader-box {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }

  &.active-stat {
    .calendar-item  {
      opacity: 1;

      p {
        opacity: .4 !important;
      }
    }
  }

  &.active {
    border-color: var(--dark);

    .calendar-item {
      color: var(--dark);
    }
    .active-item {
      background-color: white;
      color: var(--dark);
      opacity: 1;
      p {
        opacity: 1;
      }
    }
  }

  .left {
    margin-right: 2rem;

    // #toggle-satellite-type {
    //   border-color: var(--primary);
    // }

    .left-inner {


      h5 {
        font-weight: 400;
        margin-bottom: .3rem;
      }
    }

    button {
      text-align: left;
      min-width: 11rem;
      padding: 1rem 1.5rem !important;
      // border-color: var(--primary) !important;
      background-color: #f8f9fa;
      border-radius: 12px !important;
      color: black;
      font-weight: bold;

      &::after {
        margin-left: 2rem;
        top: -1rem;
        position: relative;
      }
    }
  }
  .right {
    display: flex;
    overflow-x: auto;
    background: var(--light);
    padding: .2rem 0;
    border-radius: 12px;
  }

  .calendar-item {
    padding: .3rem .9rem;
    min-width: 13rem;
    background-color: var(--light);
    border: 4px solid var(--light);
    border-radius: 12px;
    cursor: pointer;
    opacity: .5;
    transition: all .2s ease;

    p {
      font-size: 1.3rem;
      margin-bottom: .1rem;
      opacity: .5;
      transition: all .2s ease;

      svg {
        margin-right: .4rem;
      }

      span {

        &:not(:last-of-type){
          margin-right: 1rem;
        }

      }
    }

  }
}

.thar-two {
  animation: 1.5s forwards alternate animBtn;
}

@keyframes animBtn {
  0% {
    background-color: white;
    color: black ;
  }

  50% {
    background-color: var(--primary);
    color: white ;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}