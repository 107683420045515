// .annotate-wrap {
//   // position: fixed;
//   // top: 0;
//   // left: 0;
//   // max-width: 90%;
//   // max-height: 90%;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   // width: 100vw;
//   // height: 100vh;
//   // background-color: rgba(0,0,0,.3);
// }

.image-annotation {
  .rp-selected-input {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-height: 70vh;
    overflow-y: auto;
    width: 40rem;
    max-width: 80%;
    z-index: 4;
  }
}



section.annotate {
  background-color: rgba(0, 0, 0, .6);
  color: white;
  padding: 10px;
  border-radius: 4px;

  h4 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  h6 {
    margin-top: 1.5rem;
  }

  select {
    width: 100%;
    padding: 5px;
    font-weight: bold;
    font-family: 'Roboto';
  }

  .note {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}

.modal-backdrop-annotate {
  z-index: 5000;

  + .modal {
    z-index: 5002;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-left: 0 !important;

    .modal-dialog {
      margin: 0 !important;
    }
  }
}
