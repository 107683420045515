.mapboxgl-ctrl-geocoder {
  left: calc(31vw + 30px + 270px) !important;
}

@media (max-width: 1600px){
  .mapboxgl-ctrl-geocoder {
    left: calc(31vw + 30px) !important;
  }
}

@media (max-width: 992px){
  .mapboxgl-ctrl-geocoder {
    left: calc(20px) !important;
    top: 20px !important;
    max-width: calc(100% - 40px) !important;
  }
}
@media (max-width: 640px){
  .mapboxgl-ctrl-geocoder {
    margin: 0 !important;
  }
}


body .mapbox-wrap {
  .mapboxgl-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: unset !important;
    z-index: 10;
    // transform: translate(0, 0) !important;
    -webkit-transform: translate3d(0, 0, 10px);
    transform: translate3d(0, 0, 10px) !important;
    background: rgba(255, 255, 255 , .6);
    // z-index: 2; // style change: from 100
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


body {
  .mapboxgl-ctrl-logo {
    display: none;
  }
  .mapbox-wrap .mapboxgl-marker.mapboxgl-marker-anchor-center {
    display: block !important;
  }

  .mapboxgl-marker {
    svg {
      path {
        fill: var(--primary);
      }
    }
  }


  .mapboxgl-ctrl-geocoder {
    position: fixed !important;
  }



  .mapboxgl-popup {
    max-width: 60vw !important;
  }

  .popup-inner {
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        &:after {
          transform: translateX(5px);
        }
      }

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M8.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.793 8 8.146 5.354a.5.5 0 010-.708z' clip-rule='evenodd'%3E%3C/path%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 01.5-.5H11a.5.5 0 010 1H4.5A.5.5 0 014 8z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
        position: relative;
        width: 25px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 17px;
        display: flex;
        margin-left: 5px;
        transition: all .2s ease;
      }
    }
  }

  .mapboxgl-ctrl-compass {
    display: none !important;
  }


  .mapboxgl-ctrl-group {
    border-radius: 4px;
    background:transparent !important;

    button {
      background-repeat: no-repeat;
    }
  }

  .mapbox-wrap {
    .mapboxgl-ctrl {
     box-shadow: none !important;
     margin: 0;
    }



    .mapboxgl-ctrl-zoom-in,
    .mapboxgl-ctrl-zoom-out {
      background-position: center;
      background-color: white !important;
      border-radius: 8px !important;
    }
    .mapboxgl-ctrl-zoom-in {
      margin-right: 3px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29'%3E%3Cdefs%3E%3Cfilter id='zpgis7lsna' width='123.3%25' height='145.3%25' x='-11.7%25' y='-22.6%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239B9B9B' fill-rule='nonzero'%3E%3Cg filter='url(%23zpgis7lsna)' transform='translate(-1333 -48) translate(1321 34)'%3E%3Cg%3E%3Cpath d='M24.98 16c4.952 0 8.981 4.029 8.981 8.98 0 1.993-.668 3.929-1.896 5.498L37 35.413 35.413 37l-4.935-4.935c-1.57 1.228-3.505 1.895-5.497 1.895-4.952 0-8.981-4.028-8.981-8.98C16 20.03 20.029 16 24.98 16zm0 2.245c-3.714 0-6.735 3.02-6.735 6.735 0 3.715 3.021 6.735 6.736 6.735 3.714 0 6.735-3.02 6.735-6.735 0-3.714-3.02-6.735-6.735-6.735zm1.123 2.245v3.368h3.368v2.245h-3.368v3.367h-2.245v-3.367H20.49v-2.245h3.368V20.49h2.245z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .mapboxgl-ctrl-zoom-out {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29'%3E%3Cdefs%3E%3Cfilter id='c8936onz9a' width='123.3%25' height='145.3%25' x='-11.7%25' y='-22.6%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239B9B9B' fill-rule='nonzero'%3E%3Cg filter='url(%23c8936onz9a)' transform='translate(-1383 -48) translate(1321 34)'%3E%3Cg%3E%3Cpath d='M74.98 16c4.952 0 8.981 4.029 8.981 8.98 0 1.993-.668 3.929-1.896 5.498L87 35.413 85.413 37l-4.935-4.935c-1.57 1.228-3.505 1.895-5.497 1.895-4.952 0-8.981-4.028-8.981-8.98C66 20.03 70.029 16 74.98 16zm0 2.245c-3.714 0-6.735 3.02-6.735 6.735 0 3.715 3.021 6.735 6.736 6.735 3.714 0 6.735-3.02 6.735-6.735 0-3.714-3.02-6.735-6.735-6.735zm4.49 5.613v2.245h-8.98v-2.245h8.98z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .mapbox-gl-draw_trash {
      background-color: white !important;
      border-radius: 8px !important;
    }

    .mapbox-gl-draw_polygon {
      border-radius: 8px !important;
    }



    .mapboxgl-ctrl-icon {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: none !important;
    }

    .mapboxgl-popup-content {
      padding: 3px;

      .mapboxgl-popup-close-button {
        background-color: white;
        width: 3rem;
        height: 3rem;
        font-size: 2.8rem;
        border-radius: 50%;
        top: -15px;
        right: -15px;
        z-index: 2;
        padding: 0 !important;
      }
    }

  }
}




body .mapbox-wrap .mapboxgl-ctrl-geolocate {
  position: fixed;
  top: 6rem;
  left: -5.2rem;

  @media (max-width: 767px) {
    // top: -6rem;
    right: 0;
    left: unset;
  }
  // @media (max-width: 640px) {
  //   top: -5rem;
  //   left: -4.5rem;
  // }

  &:hover {
    background-color: white !important;
  }

  .mapboxgl-ctrl-icon{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E") !important;
    opacity: .4;
  }
}

.modal-new-field {
  .mapbox-wrap .mapboxgl-ctrl-geolocate {
    @media (max-width: 767px) and (min-width: 640px) {
      position: fixed;
      top: 6rem;
    }
  }
}


.mapboxgl-popup-content {
  .rp-image {
    position: relative;
  }
}

.section-map-popup {
  overflow: hidden;

  .rp-selected-input  {
    display: none !important;
  }

  canvas {
    // max-width: 100% !important;
    // height: auto !important;
  }
}

.modal-annotate.hd-photo {
  .close-wrap {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }
}