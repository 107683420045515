.payment-method {
  padding: 4rem;
  height: 100%;
  border: 2px solid var(--light);
  border-radius: 8px;

  &.stripe {

    .by-stripe {
      margin-bottom: 4rem;
    }
  }

  &.wire-transfer {
    h5 {
      font-weight: normal;
      font-size: 1.6rem;
    }

    form {
      margin-top: 1.5rem;

      button {
        width: 100%;
      }
    }
  }
}

.route-section.Cart {
  .price-wrap {
    width: 36rem;
    max-width: 100%;
    margin-top: 5rem;

    @media (max-width: 767px) {
      margin-top: 2rem;
      padding-bottom: 2rem;
    }

    .col-6 {
      display: flex;
      align-items: center;
    }

    .final-price {
      font-weight: bold;
    }
  }
}

