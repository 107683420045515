section.agro-settings {
  h4 {
    margin-bottom: 2rem;
  }
  padding-top: 2rem;
  padding-bottom: 10rem;

  button {
    border: 2px solid var(--dark);
    margin: 1.5rem 1.5rem 1.5rem 0;
  }
}