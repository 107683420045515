h1 {
  font-size: 4rem;
}

main.dashboard {
  padding-top: 2rem;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 1200px) and (max-width: 1560px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (max-width: 767px){
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mapboxgl-map {
    position: relative !important;
  }


  .dates-switch {
    display: flex;

    @media (min-width: 768px) {
      justify-content: flex-end;
    }

    @media (max-width: 767px){
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }


    input {
      border: 1px solid var(--light);

      &[name="date-start"] {
        position: relative;
        &::before {
          content: 'from';
          position: absolute;
          top: 0;
          transform: translateY(-100%);
        }
      }
      &[name="date-end"] {
        position: relative;
        &::before {
          content: 'to';
          position: absolute;
          top: 0;
          transform: translateY(-100%);
        }
      }
    }
  }
}

.recharts {
  display: flex;

  .recharts-responsive-container {
    display: flex;
    align-items: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
  header {
    margin-bottom: -5rem;
  }
  .rechart {
    width: 33.33%;

    @media (max-width: 767px){
      width: 100%;
    }

    &.bar {
      width: 66.66%;

      @media (max-width: 767px){
        width: 100%;
      }

      header {
        margin-bottom: 2rem;
      }
    }
  }

  .recharts-surface {
    overflow: visible;
  }

}

.settings-wrap {
  background-color: var(--light);
  padding: 3rem 2rem 2rem 2rem;

  .settings-header {
    display: flex;
    align-items: center;
  }
}