section.terms {
  padding-top: 3rem;

  p {
    margin-bottom: 2rem;
  }

  ol {
    li {
      list-style: initial;
    }
  }
}