.price-wrap {
  .original-price, .discount-amount-price, .discount-price {
    margin-bottom: 1rem;

    h4, p {
      font-weight: normal;
      font-size: 1.8rem;
    }

    .col-6 {
      display: flex;
      align-self: center;
    }
  }
}