.route-section.pageProfile {
  display: flex;

  .company-info {
    text-align: left;
    margin-bottom: 2rem;

    p {
      margin-bottom: 0;
    }
  }

  .section-upload-photo {
    padding-bottom: 2rem;
    border-bottom: 2px solid var(--light);
  }

  .users-wrap {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    h4 {
      font-size: 1.8rem;
    }
    p.email {
      font-size: 1.2rem;
    }
  }

  .invoice-form {
    margin-top: 3rem;
  }
}
