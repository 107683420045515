body.selected {
  .section-map-popup .img-wrap{
    pointer-events: none;
  }
}


.mapboxgl-popup-content {
  max-width: calc(100% - 30px);
  height: auto;
  transform: scale(1);

  @media (min-width: 400px) {
    // transform: scale(1.2);

    .btn-primary {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 768px) {
    // transform: scale(1.3);
  }
  @media (min-width: 1200px) {
    // transform: scale(1.4);
    .mapboxgl-popup-close-button {
      // width: 2rem !important;
      // height: 2rem !important;
    }
    .btn-primary {
      font-size: 1.2rem;
    }

  }

  p {
    padding: 0 1rem;
  }
  .pseudocolor {
    position: fixed;
    background-color: white;
    // max-width: 70vw;
  }
}

button.hd-photo {
  position: absolute;
  z-index: 1;
  top: -4rem;
}