.chat-section {
  h4 {
    margin-bottom: 2rem;
  }
}

#chat-logs-section {
  padding: 0;
  max-height: 455px;
  height: 455x;
  overflow-y: auto;
  width: 400px;
  max-width: 100%;
  margin-top: 1.2rem;

  li {
    padding: .25rem .6rem;
    margin-bottom: .6rem;

    &:nth-of-type(odd) {
      background-color: white;
    }
  }
}