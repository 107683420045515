.transition-group {
  position: relative;
}

.route-section {
//   position: absolute;
  top:0;
  left:0;
  width: 100%;
  opacity: 1;
  transition: opacity 150ms ease-in;
  animation: fade .6s forwards;
}


.fade-enter {
  opacity: 0.01;
  position: absolute;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;

}

.fade-exit.fade-exit-active {
  opacity: 0.01;
}