section.image-annotation {
  display: flex;
  align-items: center;
  justify-content: center;

  .rp-image {
    position: relative !important;
  }
}

.selected .rp-shapes {
  pointer-events: none;
}

.add-comment-wrap {
  display: flex;
  align-items: center;
  width: 25rem;
  max-width: 50vw;
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem;
  border-left: 4px solid var(--primary);

  .left {
    margin-right: 1rem;
  }

  .close-comment-info {
    position: absolute;
    top: 0rem;
    right: -2rem;
    background: white;
    padding: .1rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
