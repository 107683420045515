.alert {
  position: fixed !important;
  bottom: 40px;
  right: 30px;
  z-index: 10000;
}


@media (max-width: 1200px){
  .alert {
    bottom: 80px;
  }
}

@media (max-width: 992px){
  .alert {
    bottom: 60px;
    right: -20px;
    left: 0;
  }
}
