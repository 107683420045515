$animDuration: 3s;
.section-sms {
  position: absolute;
  bottom: 2rem;
  left: 2.5rem;

  @media (max-width: 1200px){
    bottom: 7rem;
  }

  > button {
    padding: 1.75rem;
    border-radius: 50%;
    border-color: var(--primary);
    animation: bgColorAnimation $animDuration infinite;

    svg {
      animation: fillAnimation $animDuration infinite;
    }
  }
}


@keyframes bgColorAnimation {
   0% {
    background-color: var(--light);
   } 
   50% {
    background-color: var(--primary);
   }
   100% {
    background-color: var(--light);
   }
}

@keyframes fillAnimation {
   0% {
    fill: var(--primary);
   } 
   50% {
    fill: var(--white);
   }
   100% {
    fill: var(--primary);
   }
}