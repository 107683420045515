html {
  font-size: 62.5%;

  @media (max-width: 1700px) and (max-height: 700px){
    font-size: 56.5%;
  }

  @media (max-width: 1350px) and (max-height: 700px){
    font-size: 44.5%;
  }

  @media (max-width: 1600px){
    font-size: 56.5%;
  }

  
  @media (max-width: 1024px){
    font-size: 58.5%;
  }

}


body {
  font-family: "Roboto", sans-serif;
 }


.fw700 {
  font-weight: 700;
}


h3, h4 {
  font-weight: bold;
}

.font-size-14 { font-size: 1.4rem; }

.display-5 {
  font-size: 2.5rem;
}

@media (max-width: 992px){
  h4 {
    font-size: 1.8rem !important;
  }
}

