.modal-dialog.modal-new-company,
.modal-dialog.modal-new-report,
.modal-dialog.modal-book-shoot,
.modal-dialog.modal-new-user,
.modal-dialog.modal-new-address,
.modal-dialog.modal-notifications,
.modal-dialog.aside-modal-action {
  position: fixed;
  right: 0;
  height: 100%;
  margin: 0;
  transform: translate3d(100%, 0, 0) !important;
  width: 490px;
  max-width: 100%;

  .modal-content {
    height: 100%;
    overflow-y: auto;

    > header {
      padding: 2rem 3rem 1.5rem 3rem;
      z-index: 1;
      background-color: white;
      position: sticky;
      top: 0;

      .h4 {
        font-size: 2.6rem;
      }
    }

    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .modal-footer {
      padding: 2rem 50px;
    }
  }

  form {
    flex-grow: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    &:first-of-type {
      margin-top: -2rem;
    }
  }
}

@media (max-width: 767px) {
  .modal-dialog.modal-new-company,
  .modal-dialog.modal-new-report,
  .modal-dialog.modal-book-shoot,
  .modal-dialog.modal-new-user,
  .modal-dialog.modal-new-address,
  .modal-dialog.modal-notifications,
  .modal-dialog.aside-modal-action {

    .modal-content {
      > header {
        padding: 15px 20px;
      }

      .modal-body {
        overflow: auto;
        padding-bottom: 2rem;
      }

      .modal-footer {
        padding: 2rem 20px;
      }
    }

    form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }
}

.modal-backdrop-new-company,
.modal-backdrop-new-user,
.modal-backdrop-book-shoot,
.modal-backdrop-new-address,
.modal-backdrop-notifications,
.aside-modal-backdrop-action {
  &.show {
    + .modal .modal-new-company,
    + .modal .modal-new-user,
    + .modal .modal-book-shoot,
    + .modal .modal-new-address,
    + .modal .modal-notifications,
    + .modal {
      transform: translate3d(0, 0, 0) !important;
    }
  }
}

.aside-modal-backdrop-action.show {
  + .modal .aside-modal-action {
    transform: translate3d(0, 0, 0) !important;
  }
}

.aside-modal-action {
  main {
    height: 100%;
    padding: 1rem 3rem 0 3rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .btn-wrap {
    display: flex;
    padding: 2rem;
    position: sticky;
    bottom: 0;
    border-top: 1px solid rgba(173, 173, 173, 0.2);
  }
}

.modal-backdrop {
  background-color: white;
}
.modal-header {
  padding: 2.5rem 2.5rem 1.2rem 2.5rem;
  border-bottom: 0;

  .modal-title {
    font-size: 2.8rem;
  }
}

.aside-modal-action {
  main {
    height: unset;
    padding: 0 3rem;

    @media (max-width: 767px) {
      padding: 0 2rem;
    }
  }

  .modal-body {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
  .modal-header {
    padding: 2rem 2rem 0.8rem 2rem;
  }


}
